<template>
    <div>
        订单管理
    </div>
</template>
<script>
export default {
    name:'onlineTradingOrder'
}
</script>
<style lang="scss" scoped>

</style>